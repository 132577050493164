body{
  background-color: rgb(5, 2, 57);
  color:white;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  padding: 2rem;
}


a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}
.red {
  color: red;
}
.blueish{
  color: rgb(8, 168, 189);
}
.green{
  color: rgb(10, 137, 10);
}
.blue{
  color: rgb(8, 71, 189);
}
.class :hover{
  text-decoration: underline;

}
.class p{
width:max-content;
}
